
// Import the plugin style.
//
@import "~lity/dist/lity";

.lity-close {

  font-family: $font-family-base;
  font-weight: 100;
  opacity: 0.6;
  padding: 0.5rem 1rem;
  width: auto;
  height: auto;
  transition: 0.3s ease;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    font-family: $font-family-base;
    font-weight: 100;
    opacity: 1;
    padding: 0.5rem 1rem;
  }

  &:active {
    top: 0;
  }
}

.lity-iframe-container iframe {
  box-shadow: none;
}
